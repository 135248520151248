import { Dialog } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, Input, OnChanges, Output, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Asset, AssetType } from 'addiction-components';
import { first, map, of, switchMap } from 'rxjs';
import { MediaLibraryService } from 'src/app/pages/media-library/services/media-library.service';
import { ObservableInputs } from '../../lib';
import { AssetPreviewData } from '../../models';
import { DataleanDirectiveModule } from '../../modules/directive.module';
import { AssetSelectorContainerComponent } from '../asset-selector-container/asset-selector-container.component';
import { toSignal } from '@angular/core/rxjs-interop';

type OutputVal = AssetPreviewData | undefined | string;

@Component({
	selector: 'datalean-asset-selector',
	templateUrl: './asset-selector.component.html',
	styleUrls: ['./asset-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, DataleanDirectiveModule],
	providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AssetSelectorComponent }],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetSelectorComponent implements OnChanges, ControlValueAccessor {
	private mediaLibrarySrv?: MediaLibraryService;
	private readonly inputs = new ObservableInputs(this);
	private changeCallback = (val: OutputVal) => {};
	private touchedCallback = () => {};
	protected value$ = signal<AssetPreviewData | string | undefined>(undefined);
	protected hasValue$ = computed(() => !!this.value$());

	public isOpenedModal: boolean = false;
	@Input() set value(val: AssetPreviewData | string | undefined) {
		this.value$.set(val);
	}

	/** Definisce se emettere in output solo lo UUID o l'intero oggetto. Vale anche per i reactive forms */
	@Input() emit: 'uuid' | 'object' = 'uuid';
	@Input() disabled: boolean = false;
	@Input() assetType: AssetType | undefined;
	@Input() formatRequired: string | undefined;
	@Input() readonly: boolean = false;

	@Output() changed = new EventEmitter<OutputVal>();

	// protected value$ = this.inputs.observe(() => this.value);
	protected displayValue$ = computed(() => {
		const val = this.value$();

		if (!val || !this.mediaLibrarySrv) return undefined;
		if (typeof val === 'string') return toSignal(this.mediaLibrarySrv.loadAsset(val));

		if (!['jpeg', 'png'].includes(val.format) && !val.preview?.url) {
			return toSignal(this.mediaLibrarySrv.loadAsset(val.uuid).pipe(map((asset) => asset?.preview)));
		}

		return signal<AssetPreviewData>(val);
	});

	constructor(private dialog: Dialog) {
		this.mediaLibrarySrv = inject(MediaLibraryService);
	}

	writeValue(obj: AssetPreviewData | string | undefined): void {
		this.value = obj;
	}

	registerOnChange(fn: (val: AssetPreviewData | undefined | string) => void): void {
		this.changeCallback = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.touchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	ngOnChanges(): void {
		this.inputs.onChanges();
	}

	chooseAsset() {
		const dialog = this.dialog.open<Asset>(AssetSelectorContainerComponent, {
			data: {
				selectionMode: 'single',
				assetType: this.assetType,
				formatRequired: this.formatRequired,
			},
			height: '100%',
			width: '100%',
			maxWidth: '100%',
			maxHeight: '100%',
			hasBackdrop: false,
		});
		return dialog.closed.pipe(first()).subscribe((result) => {
			if (result) {
				this.onValueChanged(result);
			}
		});
	}

	protected onValueChanged(asset?: Asset) {
		// emette tutti gli eventi necessari quando è cambiato il valore
		const value = asset ? this.getValue(asset) : undefined;
		this.value$.set(value);
		this.changed.emit(value);
		this.changeCallback(value);
		this.touchedCallback();
	}

	removeAsset() {
		this.onValueChanged(undefined);
	}

	getValue(asset: Asset | undefined): AssetPreviewData | undefined | string {
		if (this.emit === 'object') return asset;
		return asset?.uuid;
	}
}
